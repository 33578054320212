<template>
  <modal ref="orderViewModal" size="l" title="Order Details">
    <spinner v-if="!order"></spinner>

    <img
      class="border-gray-4-thin border-radius-m bg-gray-4 seller-logo"
      :src="orderVendor.smallLogoImageUrl" />

    <table class="table table--s table--truncate margin-l-vertical table--inline-start">
      <caption>
        {{
          t("ecommerce.order.fields.order")
        }}
      </caption>
      <tr>
        <th>{{ t("ecommerce.order.fields.id") }}</th>
        <td>{{ order.id }}</td>
      </tr>
      <tr>
        <th>{{ translateUcFirst("ecommerce.order.details.orderedAt") }}</th>
        <td>{{ translateDate(order.createdAt, "dateWithTime") }}</td>
      </tr>
      <tr>
        <th>{{ translateUcFirst("ecommerce.order.fields.status") }}</th>
        <td>
          {{ translateUcFirst("ecommerce.order.fields.statuses." + order.status) }}
        </td>
      </tr>
      <tr>
        <th>{{ translateUcFirst("ecommerce.order.details.shipping.method") }}</th>
        <td>{{ order.Quote.shipping.method }}</td>
      </tr>
      <tr>
        <th>{{ translateUcFirst("ecommerce.order.details.shipping.address") }}</th>
        <!-- <td>{{ order.Quote.shipping.address }}</td> -->
        <td>-</td>
      </tr>
    </table>

    <table class="table table--s table--truncate margin-l-vertical table--inline-start">
      <caption>
        {{
          t("ecommerce.order.fields.vendor")
        }}
      </caption>
      <tr>
        <th class="table--shrink table--center">
          {{ translateUcFirst("ecommerce.order.details.vendorName") }}
        </th>
        <td>{{ orderVendor.name }}</td>
      </tr>
      <tr>
        <th>
          {{ translateUcFirst("entity.user.fields.email") }}
        </th>
        <td>{{ orderVendor.Owner.email }}</td>
      </tr>
    </table>

    <table class="table table--s table--truncate margin-l-vertical table--inline-start">
      <caption>
        {{
          t("ecommerce.order.fields.buyer")
        }}
      </caption>
      <tr>
        <th class="table--shrink table--center">
          {{ translateUcFirst("ecommerce.order.details.vendorName") }}
        </th>
        <td>{{ order.Buyer.fullName }}</td>
      </tr>
      <tr>
        <th>
          {{ translateUcFirst("entity.user.fields.email") }}
        </th>
        <td>{{ order.Buyer.email }}</td>
      </tr>
    </table>

    <card title="ecommerce.order.details.purchaseInformation" class="margin-2xl-bottom">
      <cart
        :quote="order.Quote"
        :read-only="true"
        :show-action-bar="false"
        :show-header-features="false" />
    </card>
  </modal>
</template>

<script>
import Cart from "@/client/components/ecommerce/Cart.vue";

export default {
  name: "ProductModal",
  components: {
    Cart,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    orderVendor() {
      return this.order?.Vendors[0];
    },
  },
  methods: {
    open() {
      this.$refs["orderViewModal"].open();
    },
  },
};
</script>

<style lang="scss" scoped>
.details-field {
  display: block;
}

#buyer-seller-details {
  justify-content: space-evenly;
}

.seller-logo {
  width: calc(var(--font-max) * 2);
  height: calc(var(--font-max) * 2);
  display: block;
}

.with-border {
  border: 1px solid var(--c-gray-4) !important;
}

table {
  tr:nth-child(2) {
    border-top: 1px solid var(--c-gray-2);
  }
}

caption {
  color: var(--c-alt);
  margin-bottom: var(--margin-m);
  margin-top: var(--margin-m);
}
</style>
